import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from "../components/seo";
import docker from "./arm-mac/docker.png";
import perf from "./arm-mac/perf.png";
import phantomjs from "./arm-mac/phantomjs.png";
import virtualbox from "./arm-mac/virtualbox.png";
import bootcamp from "./arm-mac/boot-camp.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ARM Mac: Why I'm Worried About Virtualization" mdxType="SEO" />
    <h1>{`ARM Mac: Why I'm Worried About Virtualization`}</h1>
    {/* https://alligator.io/gatsbyjs/mdx-in-gatsby/ */}
    {/* https://www.gatsbyjs.org/docs/importing-assets-into-files/ */}
    <p>{`It's late 2020 and you just got a brand-new Mac with Apple's own ARM processors. Exciting! But what will development be like?`}</p>
    <h2>{`Docker`}</h2>
    <div style={{
      "textAlign": "center"
    }}>
  <img src={docker} width={150} />
    </div>
    <p>{`I would `}<strong parentName="p">{`expect about a 5x slowdown running Docker images.`}</strong></p>
    <p>{`Docker on a Mac utilizes a `}<strong parentName="p">{`hypervisor`}</strong>{`. Hypervisors rely on running the `}<strong parentName="p">{`same architecture on the host as the guest`}</strong>{`, and are about about 1x - 2x as slow as running natively.`}</p>
    <p>{`Since you're running ARM Mac, these hypervisors can only run ARM Linux. They can't run x86_64 Linux.`}</p>
    <p>{`What will happen instead? These tools will fall back on `}<strong parentName="p">{`emulators`}</strong>{`. Emulators can run `}<strong parentName="p">{`a different architecture between the host and the guest`}</strong>{`, but simulate the guest operating system at about 5x-10x slowdown.`}</p>
    <div style={{
      "marginBottom": "1.45rem"
    }}>
  <img src={perf} style={{
        "marginBottom": "0.2rem"
      }} />
  <div style={{
        "fontSize": "80%",
        "color": "#555",
        "lineHeight": "1.3"
      }}>
    A basic performance test comparing gzip performance on amd64 (hypervisor)
    and arm64v8 (emulator). Note that the emulator is over 6x slower. On an ARM
    Mac, the amd64 image will instead be 6x slower.
  </div>
    </div>
    <p>{`Why can't you update the Docker image to also support ARM? You theoretically could switch your backend to run ARM Linux. However, this would take months - renting out ARM instances, re-building all repositories, and a tense switch over. What if your hosting provider doesn't offer ARM instances with the same system requirements as x86_64? What if you complete this migration and find it runs at half the speed?`}</p>
    <p>{`Worse, it might be impossible if your images include files downloaded off the internet, as those are often only compiled for x86_64.`}</p>
    <div style={{
      "marginBottom": "1.45rem"
    }}>
  <img src={phantomjs} style={{
        "marginBottom": "0.2rem"
      }} />
  <div style={{
        "fontSize": "80%",
        "color": "#555",
        "lineHeight": "1.3"
      }}>
    An example of a Docker command that will only work on x86_64. PhantomJS does
    not release an arm build.
  </div>
    </div>
    <p>{`While moving your backend to ARM is far from impossible, it's a serious migration that you shouldn't take lightly. Getting a new laptop isn't enough justification to switch your backend architecture.`}</p>
    <p>{`Another option is to `}<strong parentName="p">{`run Docker remotely`}</strong>{`. You set up an x86_64 Linux server, then allow Docker to connect to it remotely. From then on, all Docker commands instead run on the server. This is also supported in Docker, `}<a parentName="p" {...{
        "href": "https://www.digitalocean.com/community/tutorials/how-to-use-a-remote-docker-server-to-speed-up-your-workflow"
      }}>{`here`}</a>{` is a tutorial on setting it up. This is what heavy Docker users will want to do.`}</p>
    <h2>{`VirtualBox`}</h2>
    <div style={{
      "textAlign": "center"
    }}>
  <img src={virtualbox} width={150} />
    </div>
    <p><strong parentName="p">{`VirtualBox won't work.`}</strong></p>
    <p>{`VirtualBox is a `}<strong parentName="p">{`hypervisor`}</strong>{`. Therefore, `}<strong parentName="p">{`it won't be able to run x86 Windows or x86 Linux`}</strong>{`.`}</p>
    <p>{`You could use VirtualBox to run ARM Windows. Windows already supports ARM, and has a similar binary translation system to Apple's, so it can run x86 binaries. However, VirtualBox only supports x86 hosts and guests and is `}<a parentName="p" {...{
        "href": "https://forums.virtualbox.org/viewtopic.php?f=8&t=98742"
      }}>{`unlikely to be ported by ARM`}</a>{`.`}</p>
    <p>{`VMWare Fusion similarly is a hypervisor that only support x86, but `}<a parentName="p" {...{
        "href": "https://twitter.com/VMwareFusion/status/1275483803536908288?s=20"
      }}>{`they're thinking about supporting ARM`}</a>{`.`}</p>
    <p>{`Instead of VirtualBox you might use QEMU, an emulator. However, QEMU is pretty low level and not often used to emulate Windows.`}</p>
    <h2>{`Boot Camp`}</h2>
    <div style={{
      "textAlign": "center"
    }}>
  <img src={bootcamp} width={150} />
    </div>
    <p><strong parentName="p">{`Boot Camp won't work.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/boot-camp"
      }}>{`Boot Camp`}</a>{` is an Apple-approved way to dual-boot Mac OS and Windows. `}<a parentName="p" {...{
        "href": "https://www.theverge.com/2020/6/24/21302213/apple-silicon-mac-arm-windows-support-boot-camp?utm_campaign=theverge&utm_content=chorus&utm_medium=social&utm_source=twitter"
      }}>{`Boot Camp will definitely not be available on ARM Macs`}</a>{`. It might be added later with the ability to run ARM Windows, though Microsoft `}<a parentName="p" {...{
        "href": "https://www.theverge.com/2020/6/24/21302213/apple-silicon-mac-arm-windows-support-boot-camp"
      }}>{`would have to approve`}</a>{`.`}</p>
    <h2>{`Should I get an ARM Mac?`}</h2>
    <p>{`The point of this post isn't to say that ARM Mac is a bad idea, but to give a realistic idea of what developing on one would look like assuming nothing changes. It's possible Apple could release more virtualization tools before the ARM Mac launches.`}</p>
    <p>{`Should you get an ARM Mac if you're a developer? If you work largely on frontend, mobile, or native apps, you'll probably be fine. But if you use virtualization often, I wouldn't recommend it. There will be a lot of problems early on, and not all of them will have solutions. My biggest concern is getting an ARM Mac and realizing I simply can't run an essential application on it.`}</p>
    <p>{`However if you like troubleshooting these issues and are excited about ARM Mac, go for it! My plan is for those kinds of people to fix these issues.`}</p>
    <p>{`Know something I don't? Have questions? Email me at `}<a href="mailto:bmalehorn@gmail.com"><a parentName="p" {...{
          "href": "mailto:bmalehorn@gmail.com"
        }}>{`bmalehorn@gmail.com`}</a></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      